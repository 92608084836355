import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { ErrorStore } from './+state/error.store';

export const errorHandlerInterceptor: HttpInterceptorFn = (req, next) => {
  const errorStore = inject(ErrorStore);

  const errorSources = {
    301: errorStore.errorOthers$,
    302: errorStore.errorOthers$,
    307: errorStore.errorOthers$,
    308: errorStore.errorOthers$,
    400: errorStore.errorOthers$,
    401: errorStore.error401$,
    404: errorStore.error404$,
    419: errorStore.error419$,
    422: errorStore.error422$,
    500: errorStore.error500$,
  };

  return next(req).pipe(
    catchError((error) => {
      if (error instanceof HttpErrorResponse) {
        const source = errorSources[error.status as keyof typeof errorSources];
        source ? source.next(error) : throwError(error);
      }
      return throwError(error);
    }),
  );
};
