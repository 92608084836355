/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { adapt } from '@state-adapt/angular';
import { Source } from '@state-adapt/rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { map, tap } from 'rxjs';
import { ApiErrorState, errorAdapter } from './error.adapter';

@Injectable({
  providedIn: 'root',
})
export class ErrorStore {
  private router = inject(Router);
  private modal = inject(NzModalService);
  private message = inject(NzMessageService);

  private initialState: ApiErrorState = {
    status: 'initial',
    message: null,
    serverMessage: null,
    errors: {},
    statusCode: -1,
  };

  errorOthers$ = new Source<any>('[Error Uncaught] Other Errors');

  error401$ = new Source<any>('[Error 401] Unauthorized Request');
  private error401RequestSource$ = this.error401$.pipe(
    tap(() => {
      this.modal.create({
        nzContent: `<div class="p-5 py-10 m-2 text-center text-2xl">
401 | Unauthorized Request
<br>
You will be redirected to the login page
</div>`,
        nzFooter: null,
        nzClosable: true,
        nzCentered: true,
        nzWidth: '50%',
        nzMaskClosable: true,
        nzBodyStyle: {
          padding: '0px',
          backgroundColor: 'white',
        },
      });

      // this.router.navigate(['/errors/unauthorized']).finally();
    }),
  );

  error404$ = new Source<any>('[Error 404] Page Not Found');
  private error404RequestSource$ = this.error404$.pipe(
    tap((err) => {
      this.message.error(err.payload.message, {
        nzDuration: 1000,
        nzPauseOnHover: true,
        nzAnimate: true,
      });

      // this.modal.create({
      //   nzContent: `<div class="p-5 py-10 m-2 text-center text-2xl">
      // 404 | Page Not Found
      // <br>
      // ${err.payload.message}
      // </div>`,
      //   nzFooter: null,
      //   nzClosable: true,
      //   nzCentered: true,
      //   nzWidth: '50%',
      //   nzMaskClosable: true,
      //   nzBodyStyle: {
      //     padding: '0px',
      //     backgroundColor: 'white',
      //   },
      // });

      // this.router.navigate(['/errors/not-found']).finally();
    }),
  );

  error419$ = new Source<any>('[Error 419] Page Expired');
  private error419RequestSource$ = this.error419$.pipe(
    tap(() => {
      this.modal.create({
        nzContent: `<div class="p-5 py-10 m-2 text-center text-lg">
419 | Page Expired
</div>`,
        nzFooter: null,
        nzClosable: true,
        nzCentered: true,
        nzWidth: '50%',
        nzMaskClosable: true,
        nzBodyStyle: {
          padding: '0px',
          backgroundColor: 'white',
        },
      });

      // this.router.navigate(['/errors/page-expired']).finally();
    }),
  );

  error422$ = new Source<HttpErrorResponse>('[Error 422] Form Validation Failed');
  private error422RequestSource$ = this.error422$.pipe(
    map((payload) => ({
      ...payload,
      payload: {
        ...payload.payload,
        message: payload.payload.error.message,
        errors: payload.payload.error.errors,
        serverMessage: payload.payload.message,
      },
    })),
  );

  error500$ = new Source<any>('[Error 401] Server Timeout/Error Occurred');
  private error500RequestSource$ = this.error500$.pipe(
    tap(() => {
      this.router.navigate(['/errors/server-error']).finally();
    }),
  );

  resetErrors$ = new Source<any>('[Error] Reset All Errors');

  private store = adapt(this.initialState, {
    adapter: errorAdapter,
    sources: {
      resetErrors: this.resetErrors$,
      401: this.error401RequestSource$,
      404: this.error404RequestSource$,
      419: this.error419RequestSource$,
      422: this.error422RequestSource$,
      500: this.error500RequestSource$,
    },
    path: 'app.errors',
  });

  vm$ = this.store.state$;
  errorMessage$ = this.store.errorMessage$;
  errorLists$ = this.store.errorLists$;
}
