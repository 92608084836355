<span class="text-red-500 text-sm w-24 my-2" *ngIf="type === 'errorItems' || type === 'errorItem' || type === 'errorMessage'">
  <ng-container *ngIf="!!message">
    <ng-container *ngTemplateOutlet="errorMessageTemplate"></ng-container>
  </ng-container>

  <ng-container *ngIf="!!errors">
    <ng-container *ngTemplateOutlet="errorItemsTemplate"></ng-container>
  </ng-container>
</span>

<ng-template #errorMessageTemplate>
  <div class="bg-red-400 rounded-md py-3 px-2 text-white w-full my-3">
    <strong class="text-sm font-normal">Error occurred:</strong> <br />
    <span class="font-bold">
      {{ message }}
    </span>
  </div>
</ng-template>

<ng-template #errorItemsTemplate>
  <ul class="list-disc pl-2 my-2">
    <ng-container *ngFor="let errI of errors; index as i">
      <li class="mb-2 pl-1 ml-3">{{ errI }}</li>
    </ng-container>
  </ul>
</ng-template>
