/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApiError } from '@escalate-backoffice/core/api-types';
import { createAdapter } from '@state-adapt/core';

export interface ApiErrorState extends ApiError {}

export const errorAdapter = createAdapter<ApiErrorState>()({
  resetErrors: (state) => {
    return {
      ...state,
      status: 'error',
      message: null,
      errors: {},
    };
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  401: (state, errorsObj: any) => {
    return {
      ...state,
      status: 'error',
      message: errorsObj.message,
      statusCode: 401,
      errors: errorsObj.errors,
    };
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  404: (state, errorsObj: any) => {
    return {
      ...state,
      status: 'error',
      message: errorsObj.message,
      statusCode: 404,
      errors: errorsObj.errors,
    };
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  419: (state, errorsObj: any) => {
    return {
      ...state,
      status: 'error',
      message: errorsObj.message,
      statusCode: 419,
      errors: errorsObj.errors,
    };
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  422: (state, errorsObj: any) => {
    return {
      ...state,
      status: 'error',
      message: errorsObj.message,
      statusCode: 422,
      errors: errorsObj.errors,
    };
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  500: (state, errorsObj: any) => {
    return {
      ...state,
      status: 'error',
      message: errorsObj.message,
      statusCode: 500,
      errors: errorsObj.errors,
    };
  },
  selectors: {
    errorMessage: (state) => state.message,
    errorLists: (state) => state.errors,
  },
});
