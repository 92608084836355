import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  effect,
  inject,
  Input,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorStore } from '../+state/error.store';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
  selector: 'ebc-error-messages',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './error-messages.component.html',
  styleUrls: ['./error-messages.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorHandlerComponent {
  private errorStore = inject(ErrorStore);
  private changeRef = inject(ChangeDetectorRef);

  private message$ = toSignal(this.errorStore.errorMessage$, {
    requireSync: true,
  });
  private errorItems$ = toSignal(this.errorStore.errorLists$, {
    requireSync: true,
  });

  @Input() errorKey: string | undefined;
  @Input() type: 'errorMessage' | 'errorItem' | 'errorItems' = 'errorItems';

  // eslint-disable-next-line
  errors: any = [];
  message: string | null = null;

  constructor() {
    effect(() => {
      if (
        this.type === 'errorItems' &&
        Object.keys(this.errorItems$()).length > 0
      ) {
        this.message = this.message$();

        for (const errorItems in this.errorItems$()) {
          this.errors?.push(...this.errorItems$()[errorItems]);
        }
      } else {
        if (this.errorKey !== undefined && this.type === 'errorItem') {
          this.errors = this.errorItems$()[this.errorKey];
        }

        if (this.type === 'errorMessage') {
          this.message = this.message$();
        }
      }

      this.changeRef.markForCheck();
    });
  }
}
